import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"

import Section from "./layout/Section"

const StyledSplitSection = styled.div`
  position: relative;
  background: ${props => props.theme.colors.backgroundGray};
  padding-bottom: 2rem;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
`

const StyledSection = styled(Section)`
  padding-bottom: 2rem;
`

const Image = styled.div`
  height: 0;
  padding-top: 66.666%;
  background-size: cover;
  background-position: center center;
  margin-left: 1rem;
  margin-right: 1rem;

  .window-loaded & {
    background-image: url(${props => props.imageUrl});
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
  }
`

const SplitSection = ({ imageUrl, children }) => (
  <StyledSplitSection>
    <Grid>
      <Row>
        <Col xs={12} sm={5}>
          <StyledSection>{children}</StyledSection>
        </Col>
      </Row>
    </Grid>
    <Image imageUrl={imageUrl} />
  </StyledSplitSection>
)

export default SplitSection
