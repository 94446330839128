const facebookReviews = [
  {
    href: "https://www.facebook.com/crystal.bygrave/posts/10156079597085095",
    cite:
      "https://developers.facebook.com/crystal.bygrave/posts/10156079597085095",
    snippet:
      "Greg cleaned my chandelier today and he did a fantastic job. He was very thorough and professional. My chandelier sparkles again. Thanks Greg!",
    name: "Crystal Cote",
    date: "Thursday, April 4, 2019",
  },
  {
    href: "https://www.facebook.com/lynn.ratsoy/posts/10156717107016217",
    cite: "https://developers.facebook.com/lynn.ratsoy/posts/10156717107016217",
    snippet:
      "I am thrilled and amazed with the cleaning job Greg did in our home. He did all our windows inside and did an awesome...",
    name: "Lynn Ratsoy",
    date: "Friday, December 14, 2018",
  },
  {
    href:
      "https://www.facebook.com/robert.mcalpine.5/posts/10210066939024131:0",
    cite:
      "https://developers.facebook.com/robert.mcalpine.5/posts/10210066939024131:0",
    snippet: "Always does  great job",
    name: "Rob McAlpine",
    date: "Tuesday, May 29, 2018",
  },
  {
    href: "https://www.facebook.com/jay.prosper.73/posts/10211959032039865:0",
    cite:
      "https://developers.facebook.com/jay.prosper.73/posts/10211959032039865:0",
    snippet:
      "Best window cleaning service ever. I have never seen these windows this clean, thanks guys great job I will definitely hire you again and recommend you to everyone",
    name: "Jay Prosper",
    date: "Monday, May 28, 2018",
  },
  {
    href: "https://www.facebook.com/taryn.lough/posts/10101318684114465:0",
    cite:
      "https://developers.facebook.com/taryn.lough/posts/10101318684114465:0",
    snippet:
      "Greg cleaned all of our windows inside and out, as well as our chandelier and high ceiling fan. We are so impressed....",
    name: "Taryn Lough",
    date: "Friday, March 23, 2018",
  },
  {
    href:
      "https://www.facebook.com/monika.nutzenberger/posts/1449133885212063:0",
    cite:
      "https://developers.facebook.com/monika.nutzenberger/posts/1449133885212063:0",
    snippet:
      "Excellent service, Dr.Squeegee is cleaning my windows for several years now and does always a wonderful cleaning job! 100% recommend Dr. Squeegee!",
    name: "Monika Nutzenberger",
    date: "Tuesday, January 9, 2018",
  },
  {
    href: "https://www.facebook.com/pdowneymaillet/posts/10155082665026932:0",
    cite:
      "https://developers.facebook.com/pdowneymaillet/posts/10155082665026932:0",
    snippet:
      "I contacted Greg at Dr. Squeegee based on a recommendation from a facebook group.  I was tired of looking at my dirty...",
    name: "Paula Downey-Maillet",
    date: "Sunday, January 7, 2018",
  },
  {
    href:
      "https://www.facebook.com/marlene.hessdorfer/posts/10155922321682208:0",
    cite:
      "https://developers.facebook.com/marlene.hessdorfer/posts/10155922321682208:0",
    snippet:
      "I would highly recommend Greg.  Clean, fast, reliable, trustworthy, he&#039;s the best!  We&#039;ve been using him for over 5 years now and wouldn&#039;t use anyone else.",
    name: "Marlene Peinsznski Hessdorfer",
    date: "Monday, December 11, 2017",
  },
]

export default facebookReviews
