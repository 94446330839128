import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { StyledSticky } from "./Sticky"

const StyledButton = styled(({ isLightBackground, ...rest }) => (
  <Link {...rest} />
))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border: 3px solid ${props => props.theme.colors.secondary};
  background-color: ${props => props.theme.colors.secondary};
  color: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 1rem;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  &:hover {
    background: ${props =>
      props.disabled ? props.theme.colors.secondary : "none"};
    border: 3px solid
      ${props =>
        props.isLightBackground ? props.theme.colors.textBlack : "white"};
    color: ${props =>
      props.isLightBackground ? props.theme.colors.textBlack : "white"};
    cursor: pointer;
  }

  ${StyledSticky} & {
    display: flex;
    margin-top: 0.5rem;
  }
`

const Button = ({ children, isLightBackground = false, ...props }) => (
  <StyledButton isLightBackground={isLightBackground} {...props}>
    {children}
  </StyledButton>
)

export default Button
