/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react"
import styled from "styled-components"
import ClipLoader from "react-spinners/ClipLoader"

import siteTheme from "../constants/siteTheme"

const StyledFacebookReview = styled.div`
  margin-top: 1.5rem;
  position: relative;
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddfe2;
  border-radius: 3px;
`

const FacebookReview = ({ href, cite, snippet, name, date }) => (
  <StyledFacebookReview>
    <div
      className="fb-post"
      data-href={href}
      data-width="auto"
      data-show-text="true"
    >
      <blockquote cite={cite} className="fb-xfbml-parse-ignore">
        <p>{snippet}</p>Posted by{" "}
        <a href="#" role="button">
          {name}
        </a>{" "}
        on&nbsp;<a href={href}>{date}</a>
        <Loader>
          <ClipLoader
            sizeUnit="rem"
            size={2}
            color={siteTheme.colors.lightGray}
            loading
          />
        </Loader>
      </blockquote>
    </div>
  </StyledFacebookReview>
)

export default FacebookReview
