import React from "react"
import styled from "styled-components"

export const StyledSticky = styled.div`
  position: sticky;
  top: calc(${props => props.theme.header.height.lg} + 1rem);
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    margin-right: 1rem;
  }
`

const Sticky = ({ children, ...props }) => (
  <StyledSticky>{children}</StyledSticky>
)

export default Sticky
