import React from "react"
import styled from "styled-components"
import Check from "@material-ui/icons/Check"

const StyledCheckedItem = styled.div`
  display: flex;
  margin-top: 1rem;
`

const StyledCheck = styled(Check)`
  fill: ${props => props.theme.colors.primary} !important;
  transform: translateY(-2px);
  margin-right: 0.375rem;
`

const StyledText = styled.div`
  grow: 1;
`

const CheckedItem = ({ children }) => (
  <StyledCheckedItem display="flex">
    <StyledCheck />
    <StyledText>{children}</StyledText>
  </StyledCheckedItem>
)

export default CheckedItem
