import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Phone from "@material-ui/icons/Phone"

import Button from "../components/button"

const StyledPhoneEstimateCta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const StyledPhoneNumber = styled.a`
  color: inherit;
  margin-top: 1rem;
  margin-right: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
`
const StyledPhone = styled(({ isLightBackground, ...rest }) => (
  <Phone {...rest} />
))`
  fill: ${props =>
    props.isLightBackground
      ? props.theme.colors.textBlack
      : "white"} !important;
  margin-right: 0.25rem;
`

const PhoneEstimateCta = ({ isLightBackground = false }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneHref
        }
      }
    }
  `)
  return (
    <StyledPhoneEstimateCta>
      <StyledPhoneNumber href={data.site.siteMetadata.phoneHref}>
        <StyledPhone isLightBackground={isLightBackground} />
        {data.site.siteMetadata.phone}
      </StyledPhoneNumber>
      <Button to="/estimate" isLightBackground={isLightBackground}>
        Get an Estimate
      </Button>
    </StyledPhoneEstimateCta>
  )
}

export default PhoneEstimateCta
