import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import "typeface-poppins"

import Card from "../components/Card"
import Link from "../components/Link"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import PhoneEstimateCta from "../components/PhoneEstimateCta"
import CheckedItem from "../components/CheckedItem"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"
import perfectionImage from "../images/compressed/indoor-window-cleaning-edmonton.jpg"
import testimonialsImage from "../images/compressed/chandelier-cleaning-edmonton.jpg"
import truckImage from "../images/compressed/outdoor-window-cleaning-edmonton.jpg"
import { Layout, Section, GridWithBackground } from "../components/layout"
import SplitSection from "../components/SplitSection"
import {
  BodyText,
  PrimaryHeading,
  SecondaryHeading,
  Subheading,
} from "../components/typography"
import facebookReviews from "../constants/facebookReviews"
import FacebookReview from "../components/FacebookReview"
import siteTheme from "../constants/siteTheme"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      windowCleaning: file(
        relativePath: { eq: "compressed/window-cleaning.jpg" }
      ) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gutterCleaning: file(
        relativePath: { eq: "compressed/gutter-cleaning.jpg" }
      ) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chandelierCleaning: file(
        relativePath: { eq: "compressed/chandelier-cleaning.jpg" }
      ) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pressureWashing: file(
        relativePath: { eq: "compressed/pressure-washing.jpg" }
      ) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "950236865343687",
        status: true,
        xfbml: true,
        version: "v5.0",
      })
    }
    const script = document.createElement("script")
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    script.src = "https://connect.facebook.net/en_US/sdk.js"
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
      delete window.FB
    }
  }, [])

  return (
    <ThemeProvider theme={siteTheme}>
      <Layout>
        <Seo
          title="Dr. Squeegee: Edmonton's Best Window Cleaners"
          description="Professional window, chandelier, gutter, and pressure cleaning. Serving Edmonton, Sherwood Park, Fort Saskatchewan, St. Albert, and area."
        />
        <Hero imageUrl={heroBackgroundImage}>
          <Grid>
            <Row>
              <Col xs={12} md={8}>
                <PrimaryHeading>
                  Edmonton's best choice for window and chandelier cleaning.
                </PrimaryHeading>
                <BodyText>
                  Professional <strong>window</strong>,{" "}
                  <strong>chandelier</strong>, <strong>gutter</strong>, and{" "}
                  <strong>pressure cleaning</strong> for the Edmonton, Sherwood
                  Park, Fort Saskatchewan, and St. Albert area.
                </BodyText>
              </Col>
            </Row>
            <PhoneEstimateCta />
          </Grid>
        </Hero>
        <Grid>
          <Section>
            <SecondaryHeading>
              Be glad you called the professionals.
            </SecondaryHeading>
            <Subheading>
              We have the equipment and the expertise to handle any type of job.
            </Subheading>
            <Link to="/services">See all our services →</Link>
            <Row>
              <Col xs={12} sm={6} md={3}>
                <Card
                  heading="Window Cleaning"
                  text="We’re experts at washing every type of window, inside and outside, and we guarantee you’ll be satisfied with the results."
                  image={data.windowCleaning}
                  url="/services#window-cleaning"
                  alt="Squeegee cleaning a window"
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card
                  heading="Chandelier Cleaning"
                  text="Winter is the perfect time for you to have your chandeliers cleaned. You’ll be thrilled and amazed to see your chandelier sparkle like never before."
                  image={data.chandelierCleaning}
                  url="/services#chandelier-cleaning"
                  alt="Hand cleaning a chandelier"
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card
                  heading="Gutter Cleaning"
                  text="Protect your home by keeping your eavestrough / gutter clean of debris. We use ladder standoffs so we won't dent your gutter."
                  image={data.gutterCleaning}
                  url="/services#gutter-cleaning"
                  alt="Cleaning leaves out of a gutter"
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Card
                  heading="Pressure Cleaning"
                  text="Clean your house, fence, deck, driveway, sidewalk, and more, with our low pressure, environmentally safe method."
                  image={data.pressureWashing}
                  url="/services#pressure-cleaning"
                  alt="Pressure cleaning a fence"
                />
              </Col>
            </Row>
          </Section>
        </Grid>
        <SplitSection imageUrl={perfectionImage}>
          <SecondaryHeading>We strive for perfection.</SecondaryHeading>
          <Subheading>
            We take pride in our work and we guarantee your satisfaction.
          </Subheading>
          <CheckedItem>
            Hand cleaned windows, frames, sills, and screens.
          </CheckedItem>
          <CheckedItem>
            Shoe covers and drop sheets to protect your carpets and furniture.
          </CheckedItem>
          <CheckedItem>On time, and in uniform.</CheckedItem>
          <CheckedItem>
            Ladder standoffs to protect your gutters and walls.
          </CheckedItem>
        </SplitSection>
        <GridWithBackground imageUrl={testimonialsImage}>
          <Section>
            <SecondaryHeading>
              See what our customers are saying.
            </SecondaryHeading>
            <Row>
              <Col xs={12} md={4}>
                <FacebookReview {...facebookReviews[4]} />
              </Col>
              <Col xs={12} md={4}>
                <FacebookReview {...facebookReviews[3]} />
              </Col>
              <Col xs={12} md={4}>
                <FacebookReview {...facebookReviews[5]} />
              </Col>
            </Row>
          </Section>
        </GridWithBackground>
        <SplitSection imageUrl={truckImage}>
          <SecondaryHeading>What are you waiting for?</SecondaryHeading>
          <Subheading>
            Call us or fill out our online form to get a free, written estimate.
          </Subheading>
          <PhoneEstimateCta isLightBackground />
        </SplitSection>
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
