import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const StyledCard = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  margin-top: 1rem;
`

const StyledCardContent = styled.div`
  padding: 0 0.625rem 0.625rem;
`

const StyledCardHeading = styled.h3`
  font-size: 1.25rem;
  margin-top: 0.625rem;
  margin-bottom: 0;
`

const StyledCardText = styled.p`
  color: ${props => props.theme.colors.gray};
  margin-top: 0.375rem;
  margin-bottom: 0;
`

const StyledCardCta = styled.div`
  margin-top: 0.5rem;
  color: ${props => props.theme.colors.secondary};

  ${StyledCard}:hover & {
    text-decoration: underline;
  }
`

const ZoomImageWrapper = styled.div`
  overflow: hidden;
`

const ZoomImg = styled(Img)`
  transition: transform ease 0.3s, opacity ease 0.3s;

  ${StyledCard}:hover & {
    transform: scale(1.05);
    opacity: 0.75;
  }
`

const Card = ({ heading, text, image, url, alt }) => (
  <StyledCard to={url}>
    <ZoomImageWrapper>
      <ZoomImg fluid={image.childImageSharp.fluid} alt={alt} />
    </ZoomImageWrapper>
    <StyledCardContent>
      <StyledCardHeading>{heading}</StyledCardHeading>
      <StyledCardText>{text}</StyledCardText>
      <StyledCardCta>Learn more →</StyledCardCta>
    </StyledCardContent>
  </StyledCard>
)

export default Card
